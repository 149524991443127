import { getTranslation } from "../../../../translations";

const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

export const reviews = [
    {
        qtdStars: 3,
        type: "tiktok",
        medias: [
            "https://tiktok.com/embed/7114045612609260806",
            "https://www.youtube.com/watch?v=EY7ELArfYfs"
        ],
        text: translation.thema.previewCardReview.text01,
        name: translation.thema.previewCardReview.name01,
        whatsapp: "(86) 9.9999-9999",
        email: "reviews@gmail.com",
        verified: 1
    },
    {
        qtdStars: 4,
        type: "tiktok",
        medias: [
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom1.jpg",
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom2.jpg",
        ],
        text: translation.thema.previewCardReview.text02,
        name: translation.thema.previewCardReview.name02,
        whatsapp: "(86) 9.9999-9999",
        email: "reviews@gmail.com",
        verified: 1
    },
    {
        qtdStars: 5,
        type: "tiktok",
        medias: [
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom3.jpg",
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom4.jpg",
        ],
        text: translation.thema.previewCardReview.text04,
        name: translation.thema.previewCardReview.name04,
        whatsapp: "(86) 9.9999-9999",
        email: "reviews@gmail.com",
        verified: 0
    },
    {
        qtdStars: 2,
        type: "image",
        medias: [
            "https://tiktok.com/embed/7114045612609260806",
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom4.jpg",
        ],
        text: translation.thema.previewCardReview.text05,
        name: translation.thema.previewCardReview.name05,
        whatsapp: "(86) 9.9999-9999",
        email: "reviews@gmail.com",
        verified: 1
    },
    {
        qtdStars: 5,
        type: "image",
        medias: [
            "https://tiktok.com/embed/7114045612609260806",
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom4.jpg",
        ],
        text: translation.thema.previewCardReview.text05,
        name: translation.thema.previewCardReview.name05,
        whatsapp: "(86) 9.9999-9999",
        email: "reviews@gmail.com",
        verified: 0
    },
    {
        qtdStars: 5,
        type: "image",
        medias: [
            "https://tiktok.com/embed/7114045612609260806",
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom4.jpg",
        ],
        text: translation.thema.previewCardReview.text06,
        name: translation.thema.previewCardReview.name06,
        whatsapp: "(86) 9.9999-9999",
        email: "reviews@gmail.com",
        verified: 1
    },

    {
        qtdStars: 5,
        type: "image",
        medias: [
            "https://tiktok.com/embed/7114045612609260806",
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom4.jpg",
        ],
        text: translation.thema.previewCardReview.text07,
        name: translation.thema.previewCardReview.name07,
        whatsapp: "(86) 9.9999-9999",
        email: "reviews@gmail.com",
        verified: 1
    },

    {
        qtdStars: 5,
        type: "image",
        medias: [
            "https://tiktok.com/embed/7114045612609260806",
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom4.jpg",
        ],
        text: translation.thema.previewCardReview.text08,
        name: translation.thema.previewCardReview.name08,
        whatsapp: "(86) 9.9999-9999",
        email: "reviews@gmail.com",
        verified: 1
    },

    {
        qtdStars: 5,
        type: "image",
        medias: [
            "https://tiktok.com/embed/7114045612609260806",
            "https://static.netshoes.com.br/produtos/regata-fila-drappy-feminina/79/D29-6252-879/D29-6252-879_zoom4.jpg",
        ],
        text: translation.thema.previewCardReview.text09,
        name: translation.thema.previewCardReview.name09,
        whatsapp: "(86) 9.9999-9999",
        email: "reviews@gmail.com",
        verified: 1
    },
]

export const questions = [
    {
        ask: translation.thema.previewCardQuestion.ask01,
        answer: translation.thema.previewCardQuestion.answer01,
        name: translation.thema.previewCardQuestion.name01,
        whatsapp: "(86) 9.9999-9999",
        email: "joaobatista@gmail.com"
    },
    {
        ask: translation.thema.previewCardQuestion.ask02,
        answer: translation.thema.previewCardQuestion.answer02,
        name: translation.thema.previewCardQuestion.name02,
        whatsapp: "(86) 9.9999-9999",
        email: "fernandoalmeida@gmail.com"
    },
    {
        ask: translation.thema.previewCardQuestion.ask03,
        answer: translation.thema.previewCardQuestion.answer03,
        name: translation.thema.previewCardQuestion.name03,
        whatsapp: "(86) 9.9999-9999",
        email: "franciscosilva@gmail.com"
    },
    {
        ask: translation.thema.previewCardQuestion.ask04,
        answer: translation.thema.previewCardQuestion.answer04,
        name: translation.thema.previewCardQuestion.name04,
        whatsapp: "(86) 9.9999-9999",
        email: "mariareis@gmail.com"
    },
]

