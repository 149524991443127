import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ModalPreviewEmail from '../modal-preview-email';
import { AlertSave, BarMenuCustom, Box, BoxActions, BoxForm, BoxLoadingCustom, ButtonDefault, ButtonSave, EditCustom, ErrorMessage, FormCustom, InputCustomItem, InputCustomItemColor, InputFileCustom, InputTextCustom, SelectCustom, TextAreaCustom, Tooltips } from './styled';
import { FaRegQuestionCircle, FaUpload } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import imageCompression from 'browser-image-compression';
import PreviewEmail from '../preview-email';
import ModalInforVideo from '../../../../components/modal-infor-video';
import { getTranslation } from '../../../../translations';
import AlertCloseBoss from '../../../../components/alert-close-boss';
import IconEdit from '../../../../assets/imgs/icon-edit.svg';
import IconClose from '../../../../assets/imgs/close-white.svg';
import IconBossSave from '../../../../assets/imgs/boss-save.svg';
import { isSave, isSaveEmail } from '../../../../utils/custom';
import ModalAlertSave from '../modal-alert-save';
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FormEmail = () => {

    const config = useSelector(state => state.reducerGetConfig)
    const dispatch = useDispatch()
    const history = useHistory()
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const configControl = useSelector(state => state.reducerControlConfig)
    const controlSaveEmailColor = useSelector(state => state.reducerControlSaveEmailColor)
    const controlSaveEmailConfig = useSelector(state => state.reducerControlSaveEmailConfig)


    const edit = useSelector(state => state.reducerControlEdit)


    const [visibleModalInforEmail, setVisibleModalInforEmail] = useState(false)

    const getSizeIframe = () => {
        console.log("getSizeIframe")
        // if (document.getElementById('meu-iframe')) {
        //     var iframe = document.getElementById('meu-iframe');
        //     var divContainer = document.getElementById('div-container');

        //     if (iframe.contentWindow.document.body) {
        //         var alturaIframe = iframe.contentWindow.document.body.scrollHeight;
        //         console.log('Altura do iFrame:', alturaIframe);
        //         divContainer.style.height = alturaIframe + 'px';
        //     } else {
        //         console.log('Não foi possível obter a altura do iFrame.');
        //     }
        // }

    }

    


    return (
        <>
            <ModalInforVideo visible={visibleModalInforEmail} setVisibleModal={setVisibleModalInforEmail} video={"https://www.youtube.com/embed/TzPHZzxYgJo"} />

            <FormCustom >
                <Box>
                    {/* <PreviewEmail />  */}

                    {/* {
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id == 5048 ?
                            <iframe width={"100%"} height={"100%"} src={`${process.env.REACT_APP_URL_EDITOR_EMAIL}/editor?name=review&app=lily&user_id_ref=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id}&token=${process.env.REACT_APP_TOKEN_EDITOR_EMAIL}`} /> :
                            <PreviewEmail />
                    } */}
                    {/* <iframe width={"100%"} height={"100%"} src={`${process.env.REACT_APP_URL_EDITOR_EMAIL}/editor?name=review&app=lily&user_id_ref=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id}&token=${process.env.REACT_APP_TOKEN_EDITOR_EMAIL}`} /> : */}

                    <BarMenuCustom onClick={() => { history.push("/personalizar") }} >
                        <IoIosArrowBack size={20} color={"#808080"} />
                        <label>{translation.thema.previewCreateReview.voltar}</label>
                        <div style={{ display: "flex", justifyContent: "center", flex: "1", margin: "0px 40px" }}>
                            <label style={{ backgroundColor: "#FFF4CC", fontSize: "14px", color: "#970", padding: "10px 20px", borderRadius: "4px" }}>
                                {translation.alert.emailRemetente}
                            </label>
                        </div>
                    </BarMenuCustom>
                    <div style={{ minHeight: "100vh" }}>
                        <iframe
                            //onload={() => { getSizeIframe() }}
                            width={"100%"} height={"100%"} src={`${process.env.REACT_APP_URL_EDITOR_EMAIL}/editor?name=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country == "BR" ? "review" : "review_es"}&app=lily&user_id_ref=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id}&token=${process.env.REACT_APP_TOKEN_EDITOR_EMAIL}`} /> :
                    </div>


                    {
                        !controlSaveEmailColor &&
                        <ModalAlertSave>


                            <span>
                                <AlertSave>
                                    <img src={IconBossSave} />
                                    <span>
                                        <label>{translation.thema.alertText}</label>
                                        <div>
                                            <label onClick={() => { dispatch({ type: "SAGA_GET_CONFIG" }) }} >
                                                {translation.thema.alertDescartar}
                                            </label>
                                            <span onClick={() => {
                                                dispatch({
                                                    type: "SAGA_UPDATE_CONFIG",
                                                    payload: {
                                                        id: config.data?.id,
                                                        color_text_button: configControl.color_text_button,
                                                        background_button: configControl.background_button,
                                                        color_text: configControl.color_text,
                                                        color_border: configControl.color_border,
                                                        background_body: configControl.background_body
                                                    }
                                                })
                                            }}
                                            >
                                                {translation.thema.alertSalvar}
                                            </span>
                                        </div>
                                    </span>
                                    {/* <img style={{ width: "7px", height: "7px", cursor: "pointer" }} src={IconClose} /> */}
                                </AlertSave>
                            </span>
                        </ModalAlertSave>
                    }

                    {
                        !controlSaveEmailConfig &&
                        <ModalAlertSave>
                            <span>
                                <AlertSave>
                                    <img src={IconBossSave} />
                                    <span>
                                        <label>{translation.thema.alertText}</label>
                                        <div>
                                            <label onClick={() => { dispatch({ type: "SAGA_GET_CONFIG" }) }} >
                                                {translation.thema.alertDescartar}
                                            </label>
                                            <span onClick={() => {

                                                if (typeof configControl.request_email_logourl == "string") {
                                                    dispatch({
                                                        type: "SAGA_UPDATE_CONFIG", payload: {
                                                            id: config.data?.id,
                                                            font_email: configControl.font_email,
                                                            request_email_buttontext: configControl.request_email_buttontext,
                                                            request_email_date: configControl.request_email_date,
                                                            request_email_subject: configControl.request_email_subject,
                                                            request_email_content: configControl.request_email_content,
                                                        }
                                                    })
                                                } else {
                                                    dispatch({
                                                        type: "SAGA_UPDATE_CONFIG", payload: {
                                                            id: config.data?.id,
                                                            font_email: configControl.font_email,
                                                            request_email_buttontext: configControl.request_email_buttontext,
                                                            request_email_date: configControl.request_email_date,
                                                            image: configControl.request_email_logourl,
                                                            request_email_subject: configControl.request_email_subject,
                                                            request_email_content: configControl.request_email_content,
                                                        }
                                                    })
                                                }
                                            }}
                                            >
                                                {translation.thema.alertSalvar}
                                            </span>
                                        </div>
                                    </span>
                                </AlertSave>
                            </span>
                        </ModalAlertSave>
                    }
                </Box>

                {/* {
                    isSaveEmail(config, configControl) && edit != "email/cores" ?
                        <span style={{ position: "absolute", left: "50%", marginLeft: "-60px" }}>
                            <EditCustom onClick={() => { dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "email/cores" }) }}>
                                <img src={IconEdit} />
                                Editar
                            </EditCustom>
                        </span> :
                        !isSaveEmail(config, configControl) &&
                        <span style={{ position: "absolute", left: "50%", marginLeft: "-350px" }}>
                            <AlertSave>
                                <img src={IconBossSave} />
                                <span>
                                    <label>{translation.thema.alertText}</label>
                                    <div>
                                        <label onClick={() => { dispatch({ type: "SAGA_GET_CONFIG" }) }} >
                                            {translation.thema.alertDescartar}
                                        </label>

                                            dispatch({
                                                type: "SAGA_UPDATE_CONFIG",
                                                payload: {
                                                    id: config.data?.id,
                                                    background_button: configControl.background_button,
                                                    color_text_button: configControl.color_text_button,
                                                    background_body: configControl.background_body,
                                                    color_text: configControl.color_text,
                                                    color_border: configControl.color_border,

                                                    font_email: configControl.font_email,
                                                    request_email_buttontext: configControl.request_email_buttontext,
                                                    request_email_date: configControl.request_email_date,
                                                    //logo: logo,
                                                    request_email_subject: configControl.request_email_subject,
                                                    request_email_content: configControl.request_email_content,
                                                }
                                            })
                                        }}
                                        >
                                            {translation.thema.alertSalvar}
                                        </span>
                                    </div>
                                </span>
                               
                            </AlertSave>
                        </span>
                } */}

            </FormCustom>


            <div style={{ marginTop: "20px", display: "none", justifyContent: "center", }}>
                <label
                    style={{ borderRadius: "30px", padding: "15px 25px", border: "1px solid rgb(241, 241, 241)", fontSize: "14px", marginBottom: "20px" }}
                >{translation.thema.formEmail.problemasConfiguracao}
                    <strong onClick={() => { window.open("https://api.whatsapp.com/send?phone=558171033488") }} style={{ marginLeft: "5px", cursor: "pointer" }}>{translation.thema.formEmail.cliqueAqui}, </strong>
                </label>
            </div>
        </>
    )
}

export default FormEmail