import { useSelector } from "react-redux";
import LoadingLogoLilyNew from "../../../../components/Loading-logo-lily-new";
import Layout from "../../../../components/tailwind/layout";
import { ContainerLoading } from "./styled";
import { useState } from "react";
import ModalInforVideo from "../../../../components/modal-infor-video";
import HelpClick from "../../../../components/HelpClick";
import { getTranslation } from "../../../../translations";
import { BoxLine, SectionLeft } from "../home/styled";
import Animation from "../../components/Animarion";
import Help from "../../components/Help";
import Options from "../../components/Options";
import TotalReviews from "../../components/TotalReviews";
import TotalQuestions from "../../components/TotalQuestions";
import TableEmailsSend from "../../components/TableEmailsSend";
import TableEmailsPending from "../../components/TableEmailPending";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import LayoutNew from "../../../../components/tailwind/layout-new";


export default function HomeNew() {
  const dashboard = useSelector(state => state.reducerDashboard)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
  const dispatch = useDispatch()
  const history = useHistory()
  const [visibleModalInforDashboard, setVisibleModalInforDashboard] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("depoimentos@login")) { dispatch({ type: "SAGA_INFOR_DASHBOARD" }) }
    else { history.push("/login") }
  }, [])

  return (
    <LayoutNew>
      <ModalInforVideo visible={visibleModalInforDashboard} setVisibleModal={setVisibleModalInforDashboard}
        video={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/zPz7L91NLGA" : `https://www.youtube.com/embed/pEy1609faso`}
      />
      {dashboard.loading ?
        <ContainerLoading>
          <LoadingLogoLilyNew />
        </ContainerLoading> :
        <div style={{ margin: "20px" }}>
          <BoxLine>
            <Animation />
            <SectionLeft>
              <Help />
              <Options />
            </SectionLeft>
          </BoxLine>
          <BoxLine>
            <TotalReviews />
            <TotalQuestions />
          </BoxLine>
          <BoxLine>
            <TableEmailsSend />
            <TableEmailsPending />
          </BoxLine>
        </div>}

      {!dashboard.loading &&
        <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
          <div onClick={() => { setVisibleModalInforDashboard(true) }}>
            <HelpClick text={translation.alert.dashboard} />
          </div>
        </div>}
    </LayoutNew>
  )
}
