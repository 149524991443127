import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { BoxFixxed, BoxFixxedMobile } from './styled';

const BarPlus = () => {

    const logout = () => {
        try {
            localStorage.removeItem("depoimentos@login");
            window.location.href = 'https://app.lily.com.br/login'
            //history.push("/login")
        } catch (error) {

        }

    }

    useEffect(function () {
        try {
            let toolbarTimeout = setInterval(() => {
                let token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;

                let tokenType = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.type;

                if (token) {
                    window.empreenderPlusApiInstancia({
                        caminhoParaRepassarRequestAoPlus: process.env.REACT_APP_API_URL + "/eplus/hub", //OBRIGATORIO
                        afiliado: "", //OPCIONAL
                        insert: "insert-toolbar-plus", //classe para inserir script se não passar insere no body - OPCIOANL
                        app: "LILY", //code do app - OBRIGATORIO                        // headers - OPCIONAL
                        callbackLogout: () => { logout() },
                        headers: {
                            Authorization: tokenType + " " + token,
                            "Content-Type": "application/json",
                        },
                        upperBannerContainer: "upperBannerContainer"
                    });

                    clearInterval(toolbarTimeout);
                }
            }, 250);
        } catch (e) {
            console.error("empreenderPlusApiInstancia - error", e);
        }
    }, []);

    return (

        window.innerWidth > 1023 ?
            <BoxFixxed>
                <div className="insert-toolbar-plus" />
            </BoxFixxed> :
            <BoxFixxedMobile>
                <div className="insert-toolbar-plus" />
            </BoxFixxedMobile>




        // <div style={{ width: "100%", height: "60px", position: "absolute", }}>
        //     <div style={{ position: "absolute", width: 'calc(100% - 300px)', marginLeft: '300px' }}>
        //         <div class="upperBannerContainer"></div>
        //         <div className="insert-toolbar-plus" style={{ position: "relative", minHeight: "62px" }}></div>
        //     </div>
        // </div>
    )
}

export default BarPlus