import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ItemTable from '../item-table'
import Pagination from "../pagination";
import { getTranslation } from '../../../../../translations';
import LoadingLogoLilyNew from '../../../../../components/Loading-logo-lily-new';
import { ContainerLoading } from '../../../../reviews/pages/home-new/styled';
import { BarFilters, BarFiltersIcon, BarFiltersLeft, Search } from '../../../../reviews/components/tailwind/table/styled';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import FiltersOrders from '../filters-orders';
import PageIntegration from '../../../../../components/page-clean';
import { toast } from 'react-toastify';
import ModalSearchAllPlatformDefault from '../../modal-search-all-platform-default';
import ModalSearchPlatform from '../../modal-search-platform';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableOrders(props) {
    const checkbox = useRef()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedOrders, setSelectedOrders] = useState([])
    const dispatch = useDispatch()
    const [page, setpage] = useState(1)
    const [perPage, setperPage] = useState(10)
    const [search, setSearch] = useState("")

    const statusSendEmail = useSelector((state) => state.reducerEmails);
    const statusSendWhats = useSelector((state) => state.reducerWhats);

    const [visibleModalSearchPlatform, setVisibleModalSearchPlatform] = useState(false)
    const [visibleModalSearchAllPlatform, setVisibleModalSearchAllPlatform] = useState(false)


    const [dateInicioFilter, setDateInicioFilter] = useState("")
    const [dateFimFilter, setDateFimFilter] = useState("")

    const [dataOrders, setDataOrders] = useState([])

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    const orders = useSelector(state => state.reducerOrders)

    useEffect(() => {
        console.log("orders", orders?.data?.data)
        orders?.data?.data?.length > 0 && setDataOrders(orders.data.data)
        orders?.data?.data == undefined && setDataOrders([])
    }, [orders])

    useLayoutEffect(() => {
        const isIndeterminate = selectedOrders.length > 0 && selectedOrders.length < dataOrders.length
        setChecked(dataOrders.length == 0 || orders.loading ? false : selectedOrders.length === dataOrders.length)
        setIndeterminate(isIndeterminate)
        checkbox.current.indeterminate = isIndeterminate
    }, [selectedOrders])

    function toggleAll() {
        setSelectedOrders(checked || indeterminate ? [] : dataOrders)
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_ORDERS", payload: {
                search: search.replace("#", ""),
                perPage: perPage,
                page: page,
                iniDate: dateInicioFilter,
                finDate: dateFimFilter,
                user_token: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.user_token
            }
        })
    }

    useEffect(() => { goSearch() }, [page,])

    const sendEmailSelected = () => {
        dispatch({
            type: "SAGA_RESEND_EMAIL_ORDERS",
            payload: { order: selectedOrders },
        });
        setSelectedOrders([])
    }

    const sendWhatsSelected = () => {
        dispatch({
            type: "SAGA_RESEND_WHATS_ORDERS",
            payload: { order: selectedOrders },
        });
        setSelectedOrders([])
    }


    useEffect(() => {

        //console.log("statusSendEmail", statusSendEmail)

        if (statusSendEmail.loading == false && statusSendEmail.error == false && statusSendEmail?.data?.length == 0) {
        } else {
            const id = "statusSendEmail2"
            if (statusSendEmail.loading == true) {
                toast.loading("aguarde...", { toastId: id })
            } else {
                if (statusSendEmail.error) toast.update(id, { render: translation.orders.ErroEnvio, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.orders.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    goSearch()
                }
            }
        }
    }, [statusSendEmail])

    useEffect(() => {
        //console.log("statusSendWhats", statusSendWhats)
        if (statusSendWhats.loading == false && statusSendWhats.error == false && statusSendWhats?.data?.length == 0) {
        } else {
            const id = "statusSendWhats"
            if (statusSendWhats.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusSendWhats.error) toast.update(id, { render: translation.orders.ErroEnvio, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.orders.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    goSearch()
                }
            }
        }
    }, [statusSendWhats])

    return (
        orders.loading ?
            <ContainerLoading>
                <LoadingLogoLilyNew />
            </ContainerLoading> :
            <>
                <ModalSearchAllPlatformDefault goSearch={goSearch} visible={visibleModalSearchAllPlatform} setVisibleModal={setVisibleModalSearchAllPlatform} />
                <ModalSearchPlatform goSearch={goSearch} visible={visibleModalSearchPlatform} setVisibleModal={setVisibleModalSearchPlatform} />

                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Pedidos</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.pedidos}
                            </p>
                        </div>
                    </div>




                    <BarFilters style={{ paddingRight: "20px" }}>
                        <span style={{ display: "flex", justifyContent: "space-between" }}>
                            <BarFiltersLeft style={{ marginRight: "20px" }}>
                                <Search>
                                    <span>
                                        <MagnifyingGlassIcon style={{ width: "20px" }} />
                                    </span>
                                    <input onKeyPress={(e) => { e.key == "Enter" && goSearch() }} type='text' placeholder={translation.orders.pesquisarPedido} value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                </Search>
                            </BarFiltersLeft>
                            <BarFiltersIcon>
                                <div style={{ display: "flex" }}>
                                    <FiltersOrders
                                        dateInicioFilter={dateInicioFilter}
                                        setDateInicioFilter={setDateInicioFilter}
                                        dateFimFilter={dateFimFilter}
                                        setDateFimFilter={setDateFimFilter}
                                        goSearch={goSearch}
                                    />
                                </div>
                            </BarFiltersIcon>
                        </span>
                    </BarFilters>

                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    {selectedOrders.length > 0 && (
                                        <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                            <button
                                                onClick={() => { sendEmailSelected() }}
                                                type="button"
                                                className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                            >
                                                {translation.orders.reenviarEmail}
                                            </button>
                                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationSak != null &&
                                                <button
                                                    onClick={() => { sendWhatsSelected() }}
                                                    type="button"
                                                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                                >
                                                    {translation.orders.reenviarWhatsapp}
                                                </button>}

                                        </div>
                                    )}
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={toggleAll}
                                                    />
                                                </th>
                                                <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.data}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Pedido
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.nome}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.orders.email}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.avaliacoes.produto}
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    {translation.orders.envioEmail}
                                                </th>
                                                {JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationSak != null &&
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        {translation.orders.envioWhatsapp}
                                                    </th>}
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {dataOrders?.map((item) => (
                                                <ItemTable goSearch={goSearch} classNames={classNames} setSelectedOrders={setSelectedOrders} selectedOrders={selectedOrders} item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination setPage={setpage} />
            </>
    )
}
