import { ArrowDownTrayIcon, ArrowPathIcon, CodeBracketIcon, EllipsisVerticalIcon, EnvelopeIcon, PhotoIcon, ShoppingBagIcon, StarIcon } from "@heroicons/react/24/outline"
import { BoxIcon, Submenu } from "../../../../cupons/components/tailwind/item-table/styled"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Message } from "../../../../reviews/components/tailwind/item-table/styled"
import { getTranslation } from "../../../../../translations"

import { TextQtd } from "./styled"
import { toast } from "react-toastify"
import { IconType } from "../../card/styled"

const ItemTable = (props) => {


    const [visibleMenu, setvisibleMenu] = useState(false)
    const [fullMessage, setfullMessage] = useState(false)
    const dispatch = useDispatch()

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const closeMenu = () => { setvisibleMenu(false) }
    useEffect(() => {
        if (visibleMenu) {
            window.addEventListener('mouseup', closeMenu);
            return () => { window.removeEventListener('mouseup', closeMenu); };
        }


    }, [visibleMenu])

    const sincProduct = () => {
        dispatch({ type: "SAGA_UPDATE_DATA_PRODUCT", payload: { id: props.item.id } })
    }

    const deleteProduct = () => {
        dispatch({
            type: "SAGA_DELETE_PRODUCT",
            payload: {
                id: props.item.id
            }
        })
    }





    return (
        <>

            <tr key={"person.email"} className={props.selectedCollects.includes(props.item) ? 'bg-gray-50 even:bg-gray-50' : "even:bg-gray-50"}>
                <td className="relative px-7 sm:w-12 sm:px-6">
                    {props.selectedCollects.includes(props.item) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-green-600" />
                    )}
                    <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={props.item.email}
                        checked={props.selectedCollects.includes(props.item)}
                        onChange={(e) =>
                            props.setSelectedCollects(
                                e.target.checked
                                    ? [...props.selectedCollects, props.item]
                                    : props.selectedCollects.filter((p) => p !== props.item),
                            )
                        }
                    />
                </td>
                <td
                    className={props.classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                        props.selectedCollects.includes(props.item) ? 'text-gray-900' : 'text-gray-900',
                    )}
                >
                    {new Date(props.item.date_review).toLocaleDateString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        {props.item.client_image_url ?
                            <img style={{ width: "32px", height: "32px", objectFit: "cover", borderRadius: "4px" }} src={props.item.client_image_url} /> :
                            <ShoppingBagIcon style={{ height: "32px" }} />}
                        <label style={{ marginRight: "20px" }}>
                            {props.item.client_name}
                        </label>
                    </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <Message full={fullMessage} onClick={() => { setfullMessage(!fullMessage) }}>
                        <label>
                            {props.item.message}
                        </label>
                    </Message>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <label style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <svg style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg" fill="orange" viewBox="0 0 24 24" stroke-width="1.5" stroke="orange" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                        </svg>
                        {props.item.rating}
                    </label>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {props.item.platform == "google" ?
                            <img style={{ height: "20px" }} src={"https://imagepng.org/wp-content/uploads/2019/08/google-icon-1.png"} />
                            : props.item.platform}
                    </span>
                </td>

                <td style={{ display: "flex", justifyContent: "flex-end" }} className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <span style={{ position: "relative" }}>
                        <EllipsisVerticalIcon onClick={() => { setvisibleMenu(true) }} style={{ height: "32px", cursor: "pointer" }} />
                        <Submenu isVisible={visibleMenu}>
                            <div onClick={() => { props.deleteCollect([props.item.id]) }}>
                                <BoxIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </BoxIcon>
                                <span>
                                    <p> {translation.avaliacoes.excluir}</p>
                                    <label> {translation.depoimentos.excluirEsseDepoimento} </label>
                                </span>
                            </div>
                        </Submenu>
                    </span>
                </td>
            </tr>
        </>

    )
}

export default ItemTable