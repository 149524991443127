import React, { useEffect, useState } from 'react'
import { Action, Container } from './styled'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import ModalConfirmation from '../../../../components/Modal-Confirmation'
import ModalInfor from "../Modal-Integration";
import { useHistory } from "react-router-dom";
import { getTranslation } from '../../../../translations'

const CardNew = (props) => {

    const [visibleModalInfor, setVisibleModalInfor] = useState(false);
    const statusDeleteIntegration = useSelector(state => state.reducerDeleteIntegration)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const [visibleModalConfirmationRastreio, setVisibleModalConfirmationRastreio] = useState(false)
    const [rastreioModal, setrastreioModal] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const [install, setInstall] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const deleteIntegration = () => {
        dispatch({ type: "SAGA_DELETE_INTEGRATION", payload: { platform: props?.item?.title == "Loja Integrada" ? "loja_integrada" : props?.item?.title?.toLowerCase()?.replace(" ", "") } })
    }
    const statusPlatforms = useSelector(state => state.reducerGetPlatforms)


    const getInfor = (platform) => {

        switch (platform) {
            case "Dropi":
                return `${translation.integrations.loja}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationDropi?.user_token}`
            case "Shopify":
                return `${translation.integrations.loja}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Nuvemshop":
                return `${translation.integrations.loja}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Mercadoshop":
                return `${translation.integrations.loja}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Custom":
                return `Site: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Montink":
                return `${translation.integrations.loja}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Hotmart":
                return `${translation.integrations.loja}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Land Page":
                return `Site: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Wix":
                return `${translation.integrations.loja}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Sak":
                return props.install ? `Token: ${statusPlatforms?.data[1]?.access_token?.substr(0, 5)} *************** ${statusPlatforms?.data[1]?.access_token?.substr(statusPlatforms?.data[1]?.access_token?.length - 5, statusPlatforms?.data[1]?.access_token?.length - 1)}` : null
            default:
                return `${translation.integrations.loja}: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
        }
    }


    const validate = () => {


        if (props.item.title == "Rastreio") {
            setrastreioModal(true)
            return null
        }

        if (props.item.title == "Shopee") {
            //history.push("/produtos")
            return null
        }

        if (props.item.title == "Dropi") {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true)
            } else if (props.install) {
                setVisibleModalConfirmation(true)
            } else {
                toast.info(translation.integrations.card.integrePrimeiro, { autoClose: false, })
            }
        } else if (props.item.title == "Rastreio") {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true)
            } else if (props.install) {
                setVisibleModalConfirmation(true)
            } else {
                toast.info(translation.integrations.card.integrePrimeiro, { autoClose: false, })
            }
        } else if (props.item.title == "Sak") {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true)
            } else if (props.install) {
                setVisibleModalConfirmation(true)
            } else {
                toast.info(translation.integrations.card.integrePrimeiro, { autoClose: false, })
            }
        } else {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                if (props.item.title.toLowerCase().replace(" ", "") === JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform) {
                    setVisibleModalConfirmation(true)
                } else if (props.item.title === "Loja Integrada" && JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform === "loja_integrada") {
                    setVisibleModalConfirmation(true)
                } else {
                    toast.info(translation.integrations.card.jaExiste, { autoClose: false, })
                }
            } else {
                setVisibleModalInfor(true)
            }
        }
    }


    // useEffect(() => {
    //     if (statusPlatforms?.data?.length > 0) {
    //         for (const key in statusPlatforms?.data) {
    //             if (statusPlatforms?.data[key]?.platform?.toLowerCase() == props?.item?.title?.toLowerCase()) {
    //                 setInstall(true)
    //                 return null
    //             }
    //         }
    //         setInstall(false)
    //     } else {
    //         setInstall(false)
    //     }
    // }, [statusPlatforms])


    useEffect(() => {
        if (!statusDeleteIntegration.loading && !statusDeleteIntegration.error && statusDeleteIntegration.data.length == 0) {
        } else {
            const id = "statusDeleteIntegration"
            if (statusDeleteIntegration.loading) {
                toast.loading(translation.integrations.card.aguarde, { toastId: id })
            } else {
                if (statusDeleteIntegration.error) {
                    toast.update(id, { render: translation.integrations.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.integrations.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalConfirmation(false)
                    //alert("teste")
                    window.location.reload()
                }
            }
        }
    }, [statusDeleteIntegration])


    return (
        <Container>

            <ModalInfor
                platform={props.item}
                visible={visibleModalInfor}
                setVisibleModal={setVisibleModalInfor}
                item={props.item}
            />
            <ModalConfirmation
                textButtom={translation.integrations.excluirIntegracao}
                confirmation={deleteIntegration}
                visible={visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={`${translation.integrations.card.desejaExcluir} ${props.item.title == "Custom" ? translation.integrations.card.integracaoManual : props.item.title == "Nuvemshop" ? translation.integrations.nuvemshop : props.item.title} ?`}
                infor={getInfor(props.item.title == "Custom" ? translation.integrations.card.integracaoManual : props.item.title)}
            />

            <ModalConfirmation
                textButtom={translation.integrations.acessarRastreio}
                confirmation={() => { window.open("https://app.rastreio.net/configuracoes#lily") }}
                visible={rastreioModal}
                setVisibleModal={setrastreioModal}
                title={translation.integrations.integracaoRastreio}
            //infor={"A integração Lily => Rastreio é feita no app rastreio"}
            />

            <img src={props.item.image} />
            <span>
                <p>
                    {props.item?.title == "Custom" ? translation.integrations.card.integracaoManual :
                        props.item?.title == "Nuvemshop" ? translation.integrations.nuvemshop :
                            props.item?.title}

                    {props.empreender &&
                        <strong style={{ marginLeft: "10px" }} className="inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">
                            EMPREENDER
                        </strong>}
                </p>
                <label>{props.item.message}</label>
            </span>
            <Action active={props.install} onClick={() => { validate() }}>
                {props.item.title == "Shopee" ? "Conectado" : props.install ? "Desconectar" : "Conectar"}
            </Action>
        </Container>
    )
}

export default CardNew