import Page from "../../components/Page";
import InputCustom from "../../components/InputCustom";
import ErrorMessage from "../../components/ErrorInput";
import { BoxSocialsBtn, BoxFormLogin, BoxSocials, BoxSocialsTitle, BtnSocial, BoxSocialsInfor, BoxEmpreenderPlusLogin } from "./styled";
import { AiFillMail } from "react-icons/ai";
import { FaGoogle, FaFacebookF, FaLock } from "react-icons/fa";
import Btn from "../../components/Btn";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useState } from "react";
import { useEffect } from "react";
import { isVisibleScript } from "../../../../utils/displayScript";
import BtnEmpreenderPlus from "../../../../assets/imgs/empreender-plus.svg";
import { parse } from "date-fns";
import { useHistory } from "react-router-dom";
import { getTranslation } from "../../../../translations";
import { getCookieFromString } from "../../../../utils/cookies";


const Home = () => {

  const history = useHistory();
  const dispatch = useDispatch()

  const statuslogin = useSelector(state => state.reducerLogin)
  const language = useSelector(state => state.reducerControlLanguage)
  const [id, setId] = useState(null)

  const [enableLoginPlusBox, setEnableLoginPlusBox] = useState(false)

  let translation = getTranslation(localStorage.getItem("depoimentos@pais") == "BR" ? "pt-br" : "es");

  useEffect(() => {
    translation = getTranslation(localStorage.getItem("depoimentos@pais") == "BR" ? "pt-br" : "es");
  }, [language])


  const formik = useFormik({
    initialValues: { email: '', password: '', },
    validationSchema: Yup.object({
      email: Yup.string().required(translation.login.home.formik.email.validacao1).email(translation.login.home.formik.email.validacao2).max(200, translation.login.home.formik.email.validacao3).min(3, translation.login.home.formik.email.validacao4),
      password: Yup.string().required(translation.login.home.formik.password.validacao1).min(process.env.REACT_APP_HABILITAR_PLUS ? 3 : 8, `${translation.login.home.formik.password.validacao2} ${process.env.REACT_APP_HABILITAR_PLUS ? 3 : 8} ${translation.login.home.formik.password.validacao3}`).max(200, translation.login.home.formik.password.validacao4),
    }),
    onSubmit: (values) => {
      dispatch({ type: "SAGA_LOGAR_LOGIN", payload: values })
    },
  });

  useEffect(() => {
    if (!(statuslogin.loading === false && statuslogin.error === false && statuslogin.data.length === 0)) {
      if (statuslogin.loading === true) {
        setId(toast.loading(translation.login.home.aguarde, { toastId: 0 }))
      } else {
        if (statuslogin.error === true) {
          toast.update(id, { render: translation.login.home.errorLogin, type: "error", isLoading: false, autoClose: 5000 });
        } else if (statuslogin.error === false) {
          toast.update(id, { render: translation.login.home.loginSucesso, type: "success", isLoading: false, autoClose: 2000 });
          formik.resetForm()
          if (statuslogin.data.data.user.isAdmin == 1) {
            history.push("/admin")
          } else {
            history.push("/")
          }
          //history.push("/admin")
        }
      }
    }
  }, [statuslogin])

  useEffect(() => {
    if (localStorage.getItem("depoimentos@login")) {
      history.push("/")
    }
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_HABILITAR_PLUS) {
      fetch(process.env.REACT_APP_EPLUS_ROTA_VERIFICAR_LOGIN, {
        credentials: 'include'
      })
        .then((val) => val.text())
        .then((val) => {
          if (val === 'logado') {
            setEnableLoginPlusBox(true);
          }
        })
        .catch((err) => { })
    }
  }, [])

  // useEffect(() => { 
  //   document.getElementsByClassName("empreender-tv-main-content")[0].style.display = "none";
  // }, [])

  let linkToLoginWithPlus = process.env.REACT_APP_EPLUS_PAGINA_APPS;

  let affiliate = localStorage.getItem("depoimentos@affiliate");

  if (affiliate != null)
    linkToLoginWithPlus += `&ref=${affiliate}`;

  let utm_source = getCookieFromString('utm_source');
  linkToLoginWithPlus += `&utm_source=${utm_source}`;

  let utm_campaign = getCookieFromString('utm_campaign');
  linkToLoginWithPlus += `&utm_campaign=${utm_campaign}`;

  let utm_medium = getCookieFromString('utm_medium');
  linkToLoginWithPlus += `&utm_medium=${utm_medium}`;

  if (enableLoginPlusBox) {
    return (
      <Page>
        <BoxEmpreenderPlusLogin>
          <a
            href={linkToLoginWithPlus}
            //href={`${process.env.REACT_APP_EPLUS_PAGINA_APPS}&ref=123221`)}:
            //href={process.env.REACT_APP_EPLUS_PAGINA_APPS} }

            //</BoxEmpreenderPlusLogin>href={`${process.env.REACT_APP_EPLUS_PAGINA_APPS + localStorage.getItem("depoimentos@affiliate").length != 0 && `&ref=${localStorage.getItem("depoimentos@affiliate")}`}`}
            className="btn-logar-plus"><img alt="Empreender Plus" style={{ marginRight: '8px' }} width="24" src="https://empreender.nyc3.digitaloceanspaces.com/landingpage/landingpage/ap8n31063713.svg" /><span>{translation.login.home.acessarPlus}</span></a>
          <BoxSocialsTitle>
            <span />
            <label>{translation.login.home.acesseCom}</label>
            <span />
          </BoxSocialsTitle>
          <span>
            <strong onClick={() => { setEnableLoginPlusBox(false) }} >{translation.login.home.emailSenha}</strong>
          </span>
        </BoxEmpreenderPlusLogin >
      </Page >
    );
  }


  return (
    <Page>
      <BoxFormLogin onSubmit={formik.handleSubmit}>
        <InputCustom>
          <span>
            <AiFillMail size={20} color={"gray"} />
          </span>
          <input name={"email"} type={"text"} placeholder={translation.login.home.email} value={formik.values.email} onChange={(e) => { e.target.value = e.target.value.trim(); formik.handleChange(e) }} />
        </InputCustom>
        {formik.errors.email && formik.touched.email && <ErrorMessage>{formik.errors.email}</ErrorMessage>}

        <InputCustom>
          <span>
            <FaLock size={20} color={"gray"} />
          </span>
          <input name={"password"} type={"password"} placeholder={translation.login.home.senha} value={formik.values.password} onChange={formik.handleChange} />
        </InputCustom>
        {formik.errors.password && formik.touched.password && <ErrorMessage>{formik.errors.password}</ErrorMessage>}

        <span>
          <Btn label={translation.login.home.entrar}
            onClick={() => { if (formik.dirty === false) toast.warning(translation.login.home.preenchaDados, { toastId: 0 }) }} />
          <label onClick={() => { history.push("/senha") }} >{translation.login.home.esqueceuSenha}</label>
        </span>
      </BoxFormLogin>
      <BoxSocials>
        <BoxSocialsTitle>
          <span />
          <label>{translation.login.home.acesseCom}</label>
          <span />
        </BoxSocialsTitle>
        <BoxSocialsBtn>
          <BtnSocial
            cursor={process.env.REACT_APP_HABILITAR_PLUS ? "pointer" : "not-allowed"}
            background={process.env.REACT_APP_HABILITAR_PLUS ? "#3b589e" : "gray"}
            onClick={() => {
              if (process.env.REACT_APP_HABILITAR_PLUS) {
                let linkToRedirect = process.env.REACT_APP_API_URL + `/eplus/auth/login-social/facebook?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`;
                if (affiliate) linkToRedirect += `&affiliate=${affiliate}`;

                window.location.href = linkToRedirect;
              }
            }}
          >
            <span>
              <FaFacebookF size={15} color={"white"} />
            </span>
            <label>Facebook</label>
          </BtnSocial>

          <BtnSocial
            cursor={process.env.REACT_APP_HABILITAR_PLUS ? "pointer" : "not-allowed"}
            background={process.env.REACT_APP_HABILITAR_PLUS ? "#db4437" : "gray"}
            onClick={() => {
              if (process.env.REACT_APP_HABILITAR_PLUS) {
                let linkToRedirect = process.env.REACT_APP_API_URL + `/eplus/auth/login-social/google?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`;
                if (affiliate) linkToRedirect += `&affiliate=${affiliate}`;

                window.location.href = linkToRedirect;
              }
            }}
          >
            <span>
              <FaGoogle size={15} color={"white"} />
            </span>
            <label>Google</label>
          </BtnSocial>

          <a
            //href={`${process.env.REACT_APP_EPLUS_DOMAIN}`}
            href={linkToLoginWithPlus}
          >
            <img
              style={{ border: "1px solid #202020", borderRadius: "50px" }}
              height="35"
              src={BtnEmpreenderPlus} />
          </a>
        </BoxSocialsBtn>
        <BoxSocialsInfor>
          <label>{translation.login.home.naoPossui}<strong onClick={() => { history.push("/cadastro") }} >{translation.login.home.registrar}</strong></label>
        </BoxSocialsInfor>
      </BoxSocials>
      {isVisibleScript(false)}
    </Page>
  )
}

export default Home
