import { useEffect, useState } from 'react'
import BoxReviews from '../box-reviews'
import BoxQuestions from '../box-questions'
import { Container, BarActions, BoxOptions, BoxActions, Option, Action, ContentsAlert, Box, BoxPreview, ContainerInfor, ResumeReviews, Number, InforResume, LayoutTitle, LayoutInfor, LayoutData, LayoutTitleText, LayoutTitleActios, LayoutInforNota, LayoutInforResume, LayoutInforFilter, LayoutInforTitle, ItemNota, LayoutInforContainer, ItemFilter } from './styled'
import { AiFillStar } from "react-icons/ai";
import { RiQuestionnaireFill } from "react-icons/ri";
import { useSelector } from 'react-redux';
import AlertClose from '../../../../components/Alert-Close';
import imgBackground from "../../../../assets/imgs/preview-background.png";
import BoxConfig from '../box-config-tema';
import { getTranslation } from '../../../../translations';
import AlertCloseBoss from '../../../../components/alert-close-boss';
import ResumoReviewsModel01 from '../resumo-reviews-model-01';
import ResumoReviewsModel02 from '../resumo-reviews-model-02';
import ResumoReviewsModel03 from '../resumo-reviews-model-03';
import ResumoReviewsModel04 from '../resumo-reviews-model-04';
import ResumoReviewsModel05 from '../resumo-reviews-model-05';
import { FaImage, FaPlay } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import LayoutLily03 from '../layout-lily-03';
import BoxReviewsUpdate from '../box-reviews-update';



const PreviewTema = () => {

  const [optionSelected, setOptionSelected] = useState("reviews")
  const [icon, setIcon] = useState({ size: 20, color: "orange" })

  const configControl = useSelector(state => state.reducerControlConfig)
  const aba = useSelector(state => state.reducerControlAba)

  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  useEffect(() => {


    setIcon({ ...icon, color: configControl.fontColor })
    if (configControl.visible_button_ask == 1 && configControl.visible_button_review == 0) {
      setOptionSelected("questions")
    } else if (configControl.visibleButtonAsk == 0 && configControl.visible_button_review == 1) {
      setOptionSelected("reviews")
    }

  }, [configControl])

  useEffect(() => {

    if (aba.length > 0) {
      setOptionSelected(aba)
    }

  }, [aba, configControl])


  const getLayoutResume = (value) => {
    switch (value) {
      case "modelo01":
        return <ResumoReviewsModel01 />
      case "modelo02":
        return <ResumoReviewsModel02 />
      case "modelo03":
        return <ResumoReviewsModel03 />
      case "modelo04":
        return <ResumoReviewsModel04 />
      case "modelo05":
        return <ResumoReviewsModel05 />
      default:
        return <ResumoReviewsModel01 />
    }
  }


  return (
    <BoxPreview>
      <Container img={imgBackground} font={configControl.font} >
        <AlertCloseBoss borderleft={true} background={"#ffffff"} close={true} margin={"0px 0px 20px 0px"} visible={true} >
          <label style={{ color: "#5C0707", alignItems: "center", margin: "0px" }} >
            {translation.thema.formTema.aquiVoce}
          </label>
          <span style={{}}>
            <label onClick={() => {
              //setVisibleModalInforTema(true) 
            }} style={{ color: "#5C0707", display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
              {translation.thema.formTema.verComo}
            </label>
          </span>
        </AlertCloseBoss>

        {configControl.layout_widget == "modelo03" ?
          <LayoutLily03 optionSelected={optionSelected} setOptionSelected={setOptionSelected} /> :
          configControl.layout_widget == "modelo01" ?
            <Box backgroundColor={configControl.background_color_primary}>
              <LayoutTitle>
                <BoxOptions style={{ alignItems: "center" }}>
                  {configControl.visible_button_review == 1 &&
                    <Option primaryColor={configControl.primary_color} backgroundColor={configControl.background_color_secondary} fontColor={configControl.font_color_primary} selected={optionSelected != "reviews" ? false : true} onClick={() => { setOptionSelected("reviews") }}>
                      <label>{translation.thema.previewTheme.avaliacoes}</label>
                    </Option>}

                  {configControl.visible_button_ask == 1 &&
                    <Option primaryColor={configControl.primary_color} backgroundColor={configControl.background_color_secondary} fontColor={configControl.font_color_primary} selected={optionSelected == "questions" ? true : false} onClick={() => { setOptionSelected("questions") }}>
                      <label>{translation.thema.previewTheme.perguntas}</label>
                    </Option>}
                </BoxOptions>

                <BoxActions style={{ alignItems: "center" }} fontColor={configControl.font_color_primary}>
                  <Action style={{ display: configControl.visible_button_review == 0 || configControl.no_review_store == 1 ? "none" : "flex" }} primaryColor={configControl.primary_color} fontColor={configControl.font_color_primary} fontColorHover={configControl.font_color_secondary}>
                    <AiFillStar size={icon.size} />
                    <label>{translation.thema.previewTheme.avaliar}</label>
                  </Action>
                  <Action style={{ display: configControl.visible_button_ask ? "flex" : "none" }} primaryColor={configControl.primary_color} fontColor={configControl.font_color_primary} fontColorHover={configControl.font_color_secondary}>
                    <RiQuestionnaireFill size={icon.size} />
                    <label>{translation.thema.previewTheme.perguntar}</label>
                  </Action>
                </BoxActions>
              </LayoutTitle>
              <LayoutInfor background={configControl.background_color_resume_primary}>
                <LayoutInforNota background={configControl.background_color_resume_secondary} fontColor={configControl.font_color_primary}>
                  <p>4.0/5.0</p>
                  <label>Baseado em 100 avaliações</label>
                  <span>
                    <AiFillStar color={configControl.star_color_primary} size={28} />
                    <AiFillStar color={configControl.star_color_primary} size={28} />
                    <AiFillStar color={configControl.star_color_primary} size={28} />
                    <AiFillStar color={configControl.star_color_primary} size={28} />
                    <AiFillStar color={configControl.star_color_secondary} size={28} />
                  </span>
                </LayoutInforNota>

                <LayoutInforResume background={configControl.background_color_resume_secondary}>
                  <LayoutInforTitle fontColor={configControl.font_color_primary}>
                    Ordenar por nota
                  </LayoutInforTitle>

                  <LayoutInforContainer>
                    <ItemNota fontColor={configControl.font_color_primary} backgroundColor={configControl.star_color_primary}>
                      <label>5</label>
                      <div />
                      <p>{"(30)"}</p>
                    </ItemNota>

                    <ItemNota fontColor={configControl.font_color_primary} backgroundColor={configControl.star_color_primary}>
                      <label>4</label>
                      <div />
                      <p>{"(40)"}</p>
                    </ItemNota>

                    <ItemNota fontColor={configControl.font_color_primary} backgroundColor={configControl.star_color_primary}>
                      <label>3</label>
                      <div />
                      <p>{"(30)"}</p>
                    </ItemNota>

                    <ItemNota fontColor={configControl.font_color_primary} backgroundColor={configControl.star_color_secondary}>
                      <label>2</label>
                      <div />
                      <p>{"(0)"}</p>
                    </ItemNota>

                    <ItemNota fontColor={configControl.font_color_primary} backgroundColor={configControl.star_color_secondary}>
                      <label>1</label>
                      <div />
                      <p>{"(0)"}</p>
                    </ItemNota>
                  </LayoutInforContainer>

                </LayoutInforResume>

                <LayoutInforFilter background={configControl.background_color_resume_secondary}>
                  <LayoutInforTitle fontColor={configControl.font_color_primary}>
                    Filtrar avaliações
                  </LayoutInforTitle>
                  <LayoutInforContainer style={{ flexDirection: "row", gap: "20px", flexWrap: "wrap" }}>
                    <ItemFilter color={configControl.font_color_secondary} background={configControl.primary_color}>
                      <FaImage size={16} /> Com imagem
                    </ItemFilter>
                    <ItemFilter color={configControl.font_color_primary} background={"tranasparent"}>
                      <FaPlay size={14} /> Com vídeo
                    </ItemFilter>
                    <ItemFilter color={configControl.font_color_primary} background={"tranasparent"}>
                      <MdVerified size={16} /> Compra verificada
                    </ItemFilter>
                  </LayoutInforContainer>
                </LayoutInforFilter>
              </LayoutInfor>
              <LayoutData>
                {optionSelected == "reviews" && <BoxReviews />}
                {optionSelected == "questions" && <BoxQuestions />}
              </LayoutData>
            </Box> :
            <Box backgroundColor={configControl.background_color_primary}>
              <BarActions>

                <BoxOptions>
                  {configControl.visible_button_review == 1 &&
                    <Option primaryColor={configControl.primary_color} backgroundColor={configControl.background_color_secondary} fontColor={configControl.font_color_primary} selected={optionSelected != "reviews" ? false : true} onClick={() => { setOptionSelected("reviews") }}>
                      <label>{translation.thema.previewTheme.avaliacoes}</label>
                    </Option>}

                  {configControl.visible_button_ask == 1 &&
                    <Option primaryColor={configControl.primary_color} backgroundColor={configControl.background_color_secondary} fontColor={configControl.font_color_primary} selected={optionSelected == "questions" ? true : false} onClick={() => { setOptionSelected("questions") }}>
                      <label>{translation.thema.previewTheme.perguntas}</label>
                    </Option>}
                </BoxOptions>


                <BoxActions fontColor={configControl.font_color_primary}>
                  <Action style={{ display: configControl.visible_button_review == 0 || configControl.no_review_store == 1 ? "none" : "flex" }} primaryColor={configControl.primary_color} fontColor={configControl.font_color_primary} fontColorHover={configControl.font_color_secondary}>
                    <AiFillStar size={icon.size} />
                    <label>{translation.thema.previewTheme.avaliar}</label>
                  </Action>
                  <Action style={{ display: configControl.visible_button_ask ? "flex" : "none" }} primaryColor={configControl.primary_color} fontColor={configControl.font_color_primary} fontColorHover={configControl.font_color_secondary}>
                    <RiQuestionnaireFill size={icon.size} />
                    <label>{translation.thema.previewTheme.perguntar}</label>
                  </Action>
                </BoxActions>
              </BarActions>

              {configControl.resume_reviews == 1 && getLayoutResume(configControl.layout_resume)}

              {configControl.layout_widget == "modelo02" &&
                <>
                  {optionSelected == "reviews" && <BoxReviewsUpdate />}
                  {optionSelected == "questions" && <BoxQuestions />}
                </>}

                {configControl.layout_widget == "default" &&
                <>
                  {optionSelected == "reviews" && <BoxReviews />}
                  {optionSelected == "questions" && <BoxQuestions />}
                </>}

              {/* {configControl.layout_widget == "modelo02" && optionSelected == "reviews" ?
                <BoxReviewsUpdate /> :
                configControl.layout_widget == "modelo02" && optionSelected == "questions" ?
                  < BoxQuestions /> : null} */}

              {/* {optionSelected == "reviews" && <BoxReviewsUpdate />}
               {optionSelected == "questions" && <BoxQuestions />} */}
            </Box>
        }

      </Container>
      {/* <AlertClose visible={true} margin="20px 0px 0px 10px" >
        <ContentsAlert>
          <label >
            Problemas no seu template?
          </label>
          <label>
            Chama no zap que corrigimos para você. <strong onClick={() => { window.open("https://wa.me/+558171033488") }}>Clique aqui.</strong>
          </label>
        </ContentsAlert>
      </AlertClose> */}

      <BoxConfig />
    </BoxPreview >

  )
}

export default PreviewTema