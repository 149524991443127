import { ArrowDownTrayIcon, ArrowPathIcon, CodeBracketIcon, EllipsisVerticalIcon, EnvelopeIcon, PhotoIcon, ShoppingBagIcon, StarIcon } from "@heroicons/react/24/outline"
import { BoxIcon, Submenu } from "../../../../cupons/components/tailwind/item-table/styled"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FaWhatsapp } from "react-icons/fa"
import { BoxImage, Message } from "../../../../reviews/components/tailwind/item-table/styled"
import { getTranslation } from "../../../../../translations"
import ModalListReviews from "../../modal-list-reviews"
import ModalListQuestions from "../../modal-list-questions"
import ModalAddReview from "../../modal-add-review";
import { TextQtd } from "./styled"

import ModalUpdate from "../../modal-edit";
import ModalCode from "../../modal-code";
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

const ItemTable = (props) => {

    const [visibleModalListReviews, setVisibleModalListReviews] = useState(false)
    const [visibleModalListQuestions, setVisibleModalListQuestions] = useState(false)
    const [visibleModalAddReview, setVisibleModalAddReview] = useState(false)


    const [visibleMenu, setvisibleMenu] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [fullMessage, setfullMessage] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const [visibleModalUpdate, setVisibleModalUpdate] = useState(false)
    const [visibleModalCode, setVisibleModalCode] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const closeMenu = () => { setvisibleMenu(false) }
    useEffect(() => {
        if (visibleMenu) {
            window.addEventListener('mouseup', closeMenu);
            return () => { window.removeEventListener('mouseup', closeMenu); };
        }


    }, [visibleMenu])

    const sincProduct = () => {
        dispatch({ type: "SAGA_UPDATE_DATA_PRODUCT", payload: { id: props.item.id } })
    }

    const deleteProduct = () => {
        dispatch({
            type: "SAGA_DELETE_PRODUCT",
            payload: {
                id: props.item.id
            }
        })
    }





    return (
        <>
            <ModalUpdate
                visible={visibleModalUpdate}
                setVisibleModal={setVisibleModalUpdate}
                item={props.item} />
            <ModalCode
                visible={visibleModalCode}
                setVisibleModal={setVisibleModalCode}
                item={props.item} />
            <ModalAddReview
                review={props.item}
                goSearch={props.goSearch}
                visible={visibleModalAddReview} setVisibleModal={setVisibleModalAddReview} />
            <ModalListReviews
                setControlUpdate={props.setControlUpdate}
                search={props.item.name} id={props.item.id}
                visible={visibleModalListReviews} setVisibleModal={setVisibleModalListReviews} />
            <ModalListQuestions
                setControlUpdate={props.setControlUpdate}
                search={props.item.name} id={props.item.id}
                visible={visibleModalListQuestions} setVisibleModal={setVisibleModalListQuestions} />


            <tr key={"person.email"} className={props.selectedProducts.includes(props.item) ? 'bg-gray-50 even:bg-gray-50' : "even:bg-gray-50"}>
                <td className="relative px-7 sm:w-12 sm:px-6">
                    {props.selectedProducts.includes(props.item) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-green-600" />
                    )}
                    <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={props.item.email}
                        checked={props.selectedProducts.includes(props.item)}
                        onChange={(e) =>
                            props.setSelectedProducts(
                                e.target.checked
                                    ? [...props.selectedProducts, props.item]
                                    : props.selectedProducts.filter((p) => p !== props.item),
                            )
                        }
                    />
                </td>
                <td
                    className={props.classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                        props.selectedProducts.includes(props.item) ? 'text-gray-900' : 'text-gray-900',
                    )}
                >
                    {new Date(props.item.created_at).toLocaleDateString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        {props.item.image_url ?
                            <img style={{ width: "32px", height: "32px", objectFit: "cover", borderRadius: "4px" }} src={props.item.image_url} /> :
                            <ShoppingBagIcon style={{ height: "32px" }} />}
                        <Message full={fullMessage} onClick={() => { setfullMessage(!fullMessage) }}>
                            <label>
                                {props.item.name}
                            </label>
                        </Message>
                    </span>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <TextQtd onClick={() => {
                        //props.item.total_review_all > 0 && setVisibleModalListReviews(true)
                        history.push(`/produtos/${props.item.id}/avaliacoes`)
                    }} style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {props.item.total_review_all}
                    </TextQtd>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <TextQtd onClick={() => {
                        //props.item.total_ask > 0 && setVisibleModalListQuestions(true)
                        history.push(`/produtos/${props.item.id}/perguntas`)
                    }} style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {props.item.total_ask}
                    </TextQtd>
                </td>

                <td style={{ display: "flex", justifyContent: "flex-end" }} className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <span style={{ position: "relative" }}>
                        <EllipsisVerticalIcon onClick={() => { setvisibleMenu(true) }} style={{ height: "32px", cursor: "pointer" }} />
                        <Submenu isVisible={visibleMenu}>
                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ? <></> :
                                <div onClick={() => { sincProduct() }}>
                                    <BoxIcon>
                                        <ArrowPathIcon style={{ width: "20px" }} />
                                    </BoxIcon>
                                    <span>
                                        <p> {"Sincronizar"}</p>
                                        <label> {translation.products.sincronizaProduto}</label>
                                    </span>
                                </div>}
                            <div>
                                <BoxIcon>
                                    <ArrowDownTrayIcon style={{ width: "20px" }} />
                                </BoxIcon>
                                <span onClick={() => {
                                    props.setImportShopeeProductSelected(props.item)
                                    props.setVisibleModalImport(true)

                                }}>
                                    <p>  {translation.products.importarAvaliacao}</p>
                                    <label> {translation.products.importarAvaliacaoProduto}</label>
                                </span>
                            </div>
                            <div>
                                <BoxIcon>
                                    <StarIcon style={{ width: "20px" }} size={20} />
                                </BoxIcon>
                                <span onClick={() => {
                                    setVisibleModalAddReview(true)
                                }}>
                                    <p>  {translation.products.criarAvaliacao}</p>
                                    <label>{translation.products.criarAvaliacaoProduto}</label>
                                </span>
                            </div>

                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                                <div onClick={() => { setVisibleModalCode(true) }}>
                                    <BoxIcon>
                                        <CodeBracketIcon style={{ width: "20px" }} />
                                    </BoxIcon>
                                    <span>
                                        <p> {"Código"}</p>
                                        <label>Código HTML e JS </label>
                                    </span>
                                </div> : <></>}

                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                                <div onClick={() => { setVisibleModalUpdate(true) }}>
                                    <BoxIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                        </svg>
                                    </BoxIcon>
                                    <span>
                                        <p> Editar</p>
                                        <label>  {translation.products.atualizarProduto}</label>
                                    </span>
                                </div> : <></>}

                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                                <div onClick={() => { deleteProduct() }}>
                                    <BoxIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>
                                    </BoxIcon>
                                    <span>
                                        <p> {translation.avaliacoes.excluir}</p>
                                        <label> {translation.MeusProdutos.excluirProduto}</label>
                                    </span>
                                </div> : <></>}
                        </Submenu>
                    </span>
                </td>
            </tr>
        </>

    )
}

export default ItemTable