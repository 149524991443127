import React from 'react'
import { AbaCustom, AbaItem, BarOrder, BoxData, BoxInfor, BoxList, Btn, BtnAsks, Container, Rating, Reviews, Row, Stars, Table } from './styled'
import { LayoutData } from '../preview-theme/styled'
import BoxReviews from '../box-reviews'
import BoxQuestions from '../box-questions'
import { useSelector } from 'react-redux'
import { AiFillStar } from 'react-icons/ai'
import { getTranslation } from '../../../../translations'

const LayoutLily03 = (props) => {

    const configControl = useSelector(state => state.reducerControlConfig)
    const aba = useSelector(state => state.reducerControlAba)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Container backgroundColor={configControl.background_color_primary}>
            <AbaCustom>
                <AbaItem background={configControl.background_color_primary} active={props.optionSelected == "reviews" ? true : false} onClick={() => { props.setOptionSelected("reviews") }}>
                    <label>
                        {translation.thema.preview.Avaliacoes} <span>{`(6)`}</span>
                    </label>
                </AbaItem>

                <AbaItem background={configControl.background_color_primary} active={props.optionSelected == "questions" ? true : false} onClick={() => { props.setOptionSelected("questions") }}>
                    <label>
                        {translation.thema.preview.duvidas} <span>{`(4)`}</span>
                    </label>
                </AbaItem>
            </AbaCustom>
            <BoxData>

                {props.optionSelected == "reviews" &&
                    <BoxInfor background={configControl.background_color_secondary}>
                        <Rating>5.0</Rating>
                        <Stars>
                            <AiFillStar size={20} color={configControl.star_color_primary} />
                            <AiFillStar size={20} color={configControl.star_color_primary} />
                            <AiFillStar size={20} color={configControl.star_color_primary} />
                            <AiFillStar size={20} color={configControl.star_color_primary} />
                            <AiFillStar size={20} color={configControl.star_color_primary} />
                        </Stars>
                        <Reviews> {translation.thema.preview.baseadoEm} <span>6  {translation.thema.preview.avaliacoes}</span></Reviews>
                        <Table>
                            <Row porcent={50} color={configControl.star_color_primary}>
                                <label>5 <AiFillStar size={16} color={configControl.star_color_primary} /></label>
                                <span>
                                    <div />
                                </span>
                                <label>3</label>
                            </Row>

                            <Row porcent={17} color={configControl.star_color_primary}>
                                <label>4 <AiFillStar size={16} color={configControl.star_color_primary} /></label>
                                <span>
                                    <div />
                                </span>
                                <label>1</label>
                            </Row>

                            <Row porcent={17} color={configControl.star_color_primary}>
                                <label>3 <AiFillStar size={16} color={configControl.star_color_primary} /></label>
                                <span>
                                    <div />
                                </span>
                                <label>1</label>
                            </Row>

                            <Row porcent={17} color={configControl.star_color_primary}>
                                <label>2 <AiFillStar size={16} color={configControl.star_color_primary} /></label>
                                <span>
                                    <div />
                                </span>
                                <label>1</label>
                            </Row>

                            <Row porcent={0} color={configControl.star_color_primary}>
                                <label>1 <AiFillStar size={16} color={configControl.star_color_primary} /></label>
                                <span>
                                    <div />
                                </span>
                                <label>0</label>
                            </Row>
                            <Btn background={configControl.star_color_primary}>
                                {translation.thema.preview.avaliarProduto}
                            </Btn>
                        </Table>
                    </BoxInfor>}
                <BoxList>
                    <BarOrder>
                        <label>Ordenar por:</label>
                        <select name="select">
                            {props.optionSelected == "reviews" &&
                                <>
                                    <option value="valor1" > {translation.thema.preview.padrao}</option>
                                    <option value="valor1" selected> {translation.thema.preview.maisRecentes}</option>
                                    <option value="valor2"> {translation.thema.preview.maisAntigas}</option>
                                    <option value="valor3">Notas positivas</option>
                                    <option value="valor3">Notas negativas</option>
                                </>}

                            {props.optionSelected == "questions" &&
                                <>
                                    <option value="valor1" > {translation.thema.preview.padrao}</option>
                                    <option value="valor1" selected> {translation.thema.preview.maisRecentes}</option>
                                    <option value="valor2"> {translation.thema.preview.maisAntigas}</option>
                                    <option value="valor3"> {translation.thema.preview.respondidas}</option>
                                    <option value="valor3"> {translation.thema.preview.naoRespondidas}</option>
                                </>}

                        </select>
                    </BarOrder>

                    <LayoutData>
                        {props.optionSelected == "questions" &&
                            <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                                <BtnAsks background={configControl.star_color_primary}> {translation.thema.preview.perguntar}</BtnAsks>
                            </div>}
                        {props.optionSelected == "reviews" && <BoxReviews />}
                        {props.optionSelected == "questions" && <BoxQuestions />}
                    </LayoutData>
                </BoxList>
            </BoxData>
        </Container >
    )
}

export default LayoutLily03