import { put, call, delay } from "redux-saga/effects";
import { POST, GET, DELETE, POST_ADMIN_CUSTOM } from "../../../../services/api";

export function* integrate(action) {

    yield put({ type: "INTEGRATION_REQUEST", })

    const result = yield call(POST, { endpoint: "/integrations", data: action.payload })
    if (result == false) {
        yield put({ type: "INTEGRATION_ERROR", payload: result ? result.data : [] })
        yield put({ type: "INTEGRATION_RESET" })
        localStorage.removeItem("wix@code")
        localStorage.removeItem("wix@instanceId")
        localStorage.removeItem("wix@integration")

    } else if (result.data.error) {
        // yield put({ type: "INTEGRATION_ERROR", payload: result.data.error ? result.data : [] })
        // yield put({ type: "INTEGRATION_RESET" })

        if (result?.data?.data?.redirect?.length > 0) {
            window.location = result?.data?.data?.redirect
        } else {
            localStorage.removeItem("wix@code")
            localStorage.removeItem("wix@instanceId")
            localStorage.removeItem("wix@integration")
            yield put({ type: "INTEGRATION_ERROR", payload: result.data.error ? result.data : [] })
            yield put({ type: "INTEGRATION_RESET" })
        }
    } else {
        yield put({ type: "INTEGRATION_SUCCESS", payload: result.data })

        localStorage.removeItem("wix@code")
        localStorage.removeItem("wix@instanceId")
        localStorage.removeItem("wix@integration")
        localStorage.removeItem("nuvemshop@code")

        if (result.data.data.platform == 'sak') {
            let local = JSON.parse(localStorage.getItem("depoimentos@login"))
            local.integrationSak = result.data.data
            localStorage.setItem('depoimentos@login', JSON.stringify(local))
        } else if (result.data.data.platform == 'dropi') {
            let local = JSON.parse(localStorage.getItem("depoimentos@login"))
            local.integrationDropi = result.data.data
            localStorage.setItem('depoimentos@login', JSON.stringify(local))


            if (result.data.data.products.length > 0) {

                yield put({ type: "SYNC_REQUEST", })
                for (const product of result.data.data.products) {

                    const resultItem = yield call(POST_ADMIN_CUSTOM, { token: result.data.data.access_token, endpoint: `/api/v1/resync/dropi`, data: product })

                    yield put({ type: "CONTROL_SYNC_PRODUCTS_UPDATE", payload: { qtdTotal: result.data.data.products.length } })
                }
                yield put({ type: "CONTROL_SYNC_PRODUCTS_RESET" })

                yield put({ type: "SYNC_SUCCESS", payload: result.data.data })
                yield put({ type: "SYNC_RESET" })
            }

        }
        //  else if (result.data.data.platform == 'custom') {
        //     let local = JSON.parse(localStorage.getItem("depoimentos@login"))
        //     local.integration = result.data.data
        //     localStorage.setItem('depoimentos@login', JSON.stringify(local))
        // } 
        else {

            //teste
            let local = JSON.parse(localStorage.getItem("depoimentos@login"))
            local.integration = result.data.data
            localStorage.setItem('depoimentos@login', JSON.stringify(local))



            yield put({ type: "SYNC_REQUEST", })
            const resultSync = yield call(POST, { endpoint: `/products/integration/${action.payload.typeIntegration}`, data: [] })
            if (resultSync === false || resultSync.data.error) {
                yield put({ type: "SYNC_ERROR", payload: resultSync.data.error ? resultSync.data : [] })
            } else {
                yield put({ type: "SYNC_SUCCESS", payload: resultSync.data })
            }
            yield put({ type: "SYNC_RESET" })
        }

        yield put({ type: "INTEGRATION_RESET" })
        yield put({ type: "SYNC_RESET" })
        yield put({ type: "CONTROL_PLATFORM_RESET" })
        yield call(platforms)
    }
}

export function* integrationWix(action) {
    yield put({ type: "INTEGRATION_WIX_REQUEST", })
    //yield delay(5000)
    const result = yield call(POST, { endpoint: "/eplus/auth/login-wix", data: action.payload })
    if (result === false) {
        yield put({ type: "INTEGRATION_WIX_ERROR" })
        window.location = `https://www.wix.com/installer/install?token=&appId=${process.env.REACT_APP_WIX_ID}&redirectUrl=${process.env.REACT_APP_URL}/integracoes/wix`
    } else if (result.data.error) {
        yield put({ type: "INTEGRATION_WIX_ERROR" })
        window.location = `https://www.wix.com/installer/install?token=&appId=${process.env.REACT_APP_WIX_ID}&redirectUrl=${process.env.REACT_APP_URL}/integracoes/wix`
    } else {
        yield put({ type: "INTEGRATION_WIX_SUCCESS", payload: result.data.data })
        window.location = result?.data?.data?.redirect
    }
    yield put({ type: "INTEGRATION_WIX_RESET" })
}

export function* platforms() {
    yield put({ type: "GET_PLATFORMS_REQUEST", })
    //yield delay(5000)
    const result = yield call(GET, { endpoint: "/integrations" })
    if (result === false) {
        yield put({ type: "GET_PLATFORMS_ERROR" })
    } else {
        yield put({ type: "GET_PLATFORMS_SUCCESS", payload: result.data.data })
    }
    //yield put({ type: "GET_PLATFORMS_RESET" })
}

export function* deleteIntegration(action) {
    yield put({ type: "INTEGRATION_DELETE_REQUEST", })
    const result = yield call(DELETE, { endpoint: `/integrations/platform/${action.payload.platform}` })
    if (result === false) {
        yield put({ type: "INTEGRATION_DELETE_ERROR" })
    } else {
        yield put({ type: "INTEGRATION_DELETE_SUCCESS", payload: result.data })
        if (action.payload.platform == "dropi") {
            let newLocal = JSON.parse(localStorage.getItem("depoimentos@login"))
            delete newLocal.integrationDropi
            localStorage.setItem('depoimentos@login', JSON.stringify(newLocal))
        } else if (action.payload.platform == "sak") {
            let newLocal = JSON.parse(localStorage.getItem("depoimentos@login"))
            delete newLocal.integrationSak
            localStorage.setItem('depoimentos@login', JSON.stringify(newLocal))
        } else {
            let newLocal = JSON.parse(localStorage.getItem("depoimentos@login"))
            delete newLocal.integration
            localStorage.setItem('depoimentos@login', JSON.stringify(newLocal))
        }

        //setTimeout(() => { platforms() }, 1000);
    }
    //yield put({ type: "INTEGRATION_DELETE_RESET" })
}

